






















// Core
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { find } from 'lodash'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'
import SystemMixin from '@/mixins/SystemMixin'

// Types
import type { INameValueItem, ReportCategoryFieldResource, ReportFieldsResource } from '@/store/types'

// Stores
import ReportsModule from '@/store/modules/reports'
import FleetReportsModule from '@/store/modules/fleet/reports'
import DriverReportsModule from '@/store/modules/driver/driver-reports'

// Components
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import ReportForm from '../components/forms/ReportForm.vue'

@Component({
  components: {
    DefaultLayout,
    ReportForm,
  },
})
export default class ReportEdit extends Mixins(NotifyMixin, SystemMixin) {
  @Prop(Array)
  private breadcrumbs!: INameValueItem[]

  private form: { name: string, fieldIds: number[][] } = {
    name: '',
    fieldIds: [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ],
  }

  private get categories (): ReportFieldsResource[] {
    return ReportsModule.fields
  }

  private get report () {
    return ReportsModule.report
  }

  private async created () {
    await ReportsModule.fetchReport(+this.$route.params.reportId)
    this.form.name = this.report?.name ?? ''
    this.form.fieldIds = this.categories.map(c => {
      const category: ReportCategoryFieldResource | undefined = find(this.report?.categories, { category: c.title })

      if (category) {
        return category.subcategories.map(s => s.value as number) ?? []
      } else {
        return []
      }
    })
  }

  private handleUpdateWithoutFormat () {
    const body = {
      name: this.form.name,
      fieldIds: this.form.fieldIds.flat(),
    }

    if (body.fieldIds.length) {
      if (this.role === 'fleet') {
        FleetReportsModule.createReportSetting({ carParkId: +this.entity, body })
          .then(() => {
            this.notifySuccess('Отчет успешно отредактирован.')
            this.handleCancel()
          })
          .catch((err) => {
            const errors = err.response.data.errors
            if (errors?.name[0] && errors?.name[0].length) {
              this.notifyError('Укажите название отчета.')
            } else {
              this.notifyError()
            }
          })
      } else {
        DriverReportsModule.createReportSetting({ carId: +this.entity, body })
          .then(() => {
            this.notifySuccess('Отчет успешно отредактирован.')
            this.handleCancel()
          })
          .catch((err) => {
            const errors = err.response.data.errors
            if (errors?.name[0] && errors?.name[0].length) {
              this.notifyError('Укажите название отчета.')
            } else {
              this.notifyError()
            }
          })
      }
    } else {
      this.notifyError('Выберите категорию')
    }
  }

  private async handleUpdate (download = false) {
    const reportId = +this.$route.params.reportId
    const body = {
      name: this.form.name,
      fieldIds: this.form.fieldIds.flat(),
    }
    if (body.fieldIds.length) {
      if (this.role === 'fleet') {
        FleetReportsModule.updateReport({ reportId, carParkId: +this.entity, body })
          .then((res) => {
            this.notifySuccess('Отчет успешно отредактирован.')
            if (download) {
              this.$bus.$emit('startReportGenerating', res.id)
            }
            this.handleCancel()
          })
          .catch((err) => {
            const errors = err.response.data.errors
            if (errors?.name[0] && errors?.name[0].length) {
              this.notifyError('Укажите название отчета.')
            } else {
              this.notifyError()
            }
          })
      } else {
        DriverReportsModule.updateReport({ reportId, carId: +this.entity, body })
          .then((res) => {
            this.notifySuccess('Отчет успешно отредактирован.')
            if (download) {
              this.$bus.$emit('startReportGenerating', res.id)
            }
            this.handleCancel()
          })
          .catch((err) => {
            const errors = err.response.data.errors
            if (errors?.name[0] && errors?.name[0].length) {
              this.notifyError('Укажите название отчета.')
            } else {
              this.notifyError()
            }
          })
      }
    } else {
      this.notifyError('Выберите категорию')
    }
  }

  private handleCancel () {
    this.$router.replace({ name: 'reports' })
  }
}
